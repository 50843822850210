<template>
  <div :class="customClass">
    <CLink @click="roleModal"><CIcon name="cil-cog"/></CLink>
  </div>
</template>

<script>
export default {
    props: {
        elementKey: String,
        keyPage: String,
        actionKey: String,
        customClass: String
    },
    methods: {
        roleModal(){
            this.$store.dispatch('permission/getPermission', this.elementKey).then(response => {
                
                const params = {
                    element_key: this.elementKey,
                    page_name: this.keyPage,
                    action: this.actionKey,
                    data: response
                }

                this.$store.dispatch('permission/permissionModalOpen', params)
            })
        }
    },
}
</script>

<style>
    .headerBtn{
        position:absolute; 
        right:10px; 
        top:0px;
        z-index:10;
    }
    .headerBtn2{
        position:absolute; 
        right:5px; 
        top:-5px;
        z-index:10;
    }
    .tableBtn{
        position: absolute;
        top: -11px;
        right: -4px;
        z-index:10;
    }
    .chartBtn{
        position: absolute;
        right: 8px;
        top: -4px;
        z-index: 10;
    }
    .cardBtn{
        position: absolute;
        right: -9px;
        top: -12px;
        z-index: 10;
    }
    .iconBtn{
        position: absolute;
        right: -5px;
        top: -12px;
        z-index: 10;
    }
    .sidebarBtn{
        position: absolute;
        right: 1px;
        top: -4px;
        z-index: 10;
    }
    .headerBtn3{
        position: absolute;
        right: -8px;
        top: -11px;
        z-index: 10;
    }
    .headerBtn4 {
        position: absolute;
        right: -7px;
        top: -13px;
        z-index: 10;
    }
    .tableBtn2 {
        position: absolute;
        top: -11px;
        right: 35px;
        z-index: 10;
    }
</style>