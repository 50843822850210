<template>
    <CModal
      :show.sync="dangerModal"
      title="Delete confirmation"
      :no-close-on-backdrop="true"
      :centered="true"
      size="sm"
      color="dark"
    >
      Are you sure you want to delete <b v-if="form_delete">{{form_delete.services.services_name}}</b>?
      <template #footer>
        <CButton @click="confirmDelete(form_delete.id)" color="success" class="branding-btn">YES</CButton>
        <CButton @click="dangerModal = false" color="danger">CANCEL</CButton>
      </template>
    </CModal>
</template>
<script>
export default {
    data(){
      return {
        dangerModal: false,
        form_delete: '',
      }
    },
    props: ['deleteModalData'],
    watch: {
      deleteModalData(newVal){
        this.form_delete = newVal.data;
        this.dangerModal = true;
      }
    },
    methods: {
      confirmDelete(){
        this.$store.dispatch('discount/removeDiscount', this.form_delete.id).then(() => {
        this.dangerModal = false; 
        });
      },
    },
    created(){
      
    }
}
</script>