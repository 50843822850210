<template>
    <div>
        <CDataTable
            :items="servicesData"
            :fields="fields"
            items-per-page-select
            :items-per-page="5"
            hover
            pagination
            table-filter
            >
            <template #action="{item}">
                <td>
                    <CButtonGroup>
                    <div style="position:relative;" v-if="$ability.can('update', 'W0FCcS7')">
                        <CButton size="sm" @click="getValueServices(item)" color="info"><CIcon name="cil-pencil"/></CButton>
                        <RoleModal v-if="$store.state.permission.permission_settings" elementKey="W0FCcS7" customClass="tableBtn" actionKey="update" :keyPage="$route.name"/>
                    </div>
                    <div style="position:relative;" v-if="$ability.can('delete', 'SwAMLeG')">
                        <CButton size="sm" @click="getValueDelServices(item)" color="danger"><CIcon name="cil-trash"/></CButton>
                        <RoleModal v-if="$store.state.permission.permission_settings" elementKey="SwAMLeG" customClass="tableBtn" actionKey="delete" :keyPage="$route.name"/>
                    </div>
                    </CButtonGroup>
                </td>
            </template>
            <template #show_details="{item, index}">
                <td class="py-2">
                 <div style="position:relative;" v-if="$ability.can('update', 'TKbTDLr')">
                    <CButton
                        color="primary"
                        variant="outline"
                        square
                        size="sm"
                        @click="toggleDetails(item, index)"
                    >
                        {{Boolean(item._toggled) ? 'Hide Variation' : 'Show Variation'}}
                    </CButton>
                    <RoleModal v-if="$store.state.permission.permission_settings" elementKey="TKbTDLr" customClass="tableBtn2" actionKey="update" :keyPage="$route.name"/>
                  </div>
                </td>
            </template>
            <template #details="{item}">
                <CCollapse :show="Boolean(item._toggled)" :duration="collapseDuration">
                <CCardBody>
                    
                    <div v-for="variation in item.variations" :key="variation.id">
                        {{variation.size.size_name}}
                        <CInput type="hidden" v-model="variation.id"></CInput>
                        <CInput prepend="₱" v-model="variation.price" @change="saveVariant(variation.id, variation.price)">
                        </CInput>
                    </div>
                </CCardBody>
                </CCollapse>
            </template>
        </CDataTable>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import RoleModal from "../../containers/RoleModal"

export default {
  name: 'Table',
  components: {
    RoleModal
  },
  data() {
      return {
          collapseDuration: 0,
          servicesData: []
      }
  },
  props: {
    items: Array,
    fields: {
      type: Array,
      default () {
        return [
        { key: 'services_name', _style:'min-width:40%' },
        { 
            key: 'show_details', 
            label: '', 
            _style: 'min-width:1%'
        },
        { key: 'action', _style:'min-width:20%;' },
        ]
      }
    },
    caption: {
      type: String,
      default: 'Table'
    },
    hover: Boolean,
    striped: Boolean,
    border: Boolean,
    small: Boolean,
    fixed: Boolean,
    dark: Boolean
  },
  computed: {
    ...mapGetters({ 
        permission: "permission/permission",
    }),
  },
  watch: {
    items(newVal){
        this.servicesData = newVal.map((item, toggle_id) => {return {...item, toggle_id}})
    }
  },
  methods: {
    getBadge (status) {
      return status === 'Active' ? 'success'
        : status === 'Inactive' ? 'secondary'
          : status === 'Pending' ? 'warning'
            : status === 'Banned' ? 'danger' : 'primary'
    },
    toggleDetails (item) {

      this.$set(this.servicesData[item.toggle_id], '_toggled', !item._toggled)
      this.collapseDuration = 300
      this.$nextTick(() => { this.collapseDuration = 0})
    },
    getValueServices(data){
      this.$emit('event_child', data, 'edit', 'services');
    },  
    getValueDelServices(data){
      this.$emit('event_child', data, 'delete', 'services');
    },
    getValue(data){
      this.$emit('event_child', data, 'edit', 'discount');
    },
    getValueDel(data){
      this.$emit('event_child', data, 'delete', 'discount');
    },
    saveVariant(id, price){
        var data = [id, price]
        this.$store.dispatch('services/saveVariant', data);
    },
  },
  created(){
    if(this.permission){
      for(let i = 0; i < this.permission.length; i++){
        if(this.permission[i].route_name == "Customize"){
          this.createPermission = this.permission[i].create;
          this.updatePermission = this.permission[i].update;
          this.deletePermission = this.permission[i].delete;
        }
      }
    }
  }
}
</script>
