<template>
  <div>
    <CRow>
      <CCol lg="6">
          <CCard>
          <CCardHeader style="position:relative;">
            <strong>Vehicle </strong>
            <div class="card-header-actions" v-if="$ability.can('create', '8eYeaz9')">
              <div style="position:absolute; right:50px; top:8px;">
                <CButton @click="openSyncModal('vehicle')" size="sm" color="info"><CIcon name="cil-sync"/></CButton>
                <RoleModal v-if="$store.state.permission.permission_settings" elementKey="8eYeaz9" customClass="headerBtn4" actionKey="create" :keyPage="$route.name"/>
              </div>
              <div style="position:absolute; right:10px; top:8px;">
                <CButton @click="myModal = true" size="sm" class="branding-btn"><CIcon name="cil-plus"/></CButton>
                <RoleModal v-if="$store.state.permission.permission_settings" elementKey="8eYeaz9" customClass="headerBtn4" actionKey="create" :keyPage="$route.name"/>
              </div>
            </div>
          </CCardHeader>
          <CCardBody>
            <CTableWrapper
            :items="getShuffledUsersData()"
            striped
            v-on:event_child="eventChild"
            caption="Users"
            />
          </CCardBody>
        </CCard>
      </CCol>

      <CCol lg="6">
          <CCard>
          <CCardHeader style="position:relative;">
            <strong>Services</strong>
            <div class="card-header-actions" v-if="$ability.can('create', 'Wejv5VY')">
              <div style="position:absolute; right:50px; top:8px;">
                <CButton @click="openSyncModal('services')" size="sm" color="info"><CIcon name="cil-sync"/></CButton>
                <RoleModal v-if="$store.state.permission.permission_settings" elementKey="8eYeaz9" customClass="headerBtn4" actionKey="create" :keyPage="$route.name"/>
              </div>
              <div style="position:absolute; right:10px; top:8px;">
                <CButton @click="servicesModal = true" size="sm" class="branding-btn"><CIcon name="cil-plus"/></CButton>
                <RoleModal v-if="$store.state.permission.permission_settings" elementKey="Wejv5VY" customClass="headerBtn4" actionKey="create" :keyPage="$route.name"/>
              </div>
            </div>
          </CCardHeader>
          <CCardBody>
            <ServicesTable
            :items="services"
            v-on:event_child="eventChild"
            />
            <!-- <CDataTable
                :items="usersData"
                :fields="fields"
                items-per-page-select
                :items-per-page="5"
                hover
                pagination
                table-filter
                >
                <template #action="{item}">
                    <td>
                      <CButtonGroup>
                        <div style="position:relative;">
                            <CButton size="sm" @click="getValueServices(item)" color="info"><CIcon name="cil-pencil"/></CButton> &nbsp;
                            <RoleModal v-if="$store.state.permission.permission_settings" elementKey="W0FCcS7" customClass="tableBtn" actionKey="update" :keyPage="$route.name"/>
                        </div>
                        <div style="position:relative;">
                            <CButton size="sm" @click="getValueDelServices(item)" color="danger"><CIcon name="cil-trash"/></CButton>
                            <RoleModal v-if="$store.state.permission.permission_settings" elementKey="SwAMLeG" customClass="tableBtn" actionKey="delete" :keyPage="$route.name"/>
                        </div>
                      </CButtonGroup>
                    
                    </td>
                </template>
                <template #show_details="{item, index}">
                    <td class="py-2">
                    <CButton
                        color="primary"
                        variant="outline"
                        square
                        size="sm"
                        @click="toggleDetails(item, index)"
                    >
                        {{Boolean(item._toggled) ? 'Hide Variation' : 'Show Variation'}}
                    </CButton>
                    </td>
                </template>
                <template #details="{item}">
                    <CCollapse :show="Boolean(item._toggled)" :duration="collapseDuration">
                    <CCardBody>
                        
                        <div v-for="variation in item.variations" :key="variation.id">
                            {{variation.size.size_name}}
                            <CInput type="hidden" v-model="variation.id"></CInput>
                            <CInput prepend="₱" v-model="variation.price" @change="saveVariant(variation.id, variation.price)">
                            </CInput>
                        </div>
                    </CCardBody>
                    </CCollapse>
                </template>
            </CDataTable> -->
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <CRow>
      <CCol lg="6">
        <CCard>
          <CCardHeader>
            <strong>Discounts</strong>
            <div class="card-header-actions" v-if="$ability.can('create', 'OG2gZCO')">
              <div style="position:absolute; right:50px; top:8px;">
                <CButton @click="openSyncModal('discount')" size="sm" color="info"><CIcon name="cil-sync"/></CButton>
                <RoleModal v-if="$store.state.permission.permission_settings" elementKey="8eYeaz9" customClass="headerBtn4" actionKey="create" :keyPage="$route.name"/>
              </div>
              <div style="position:absolute; right:10px; top:8px;">
                <CButton @click="addDiscountModal = new Date()" size="sm" class="branding-btn"><CIcon name="cil-plus"/></CButton>
                <RoleModal v-if="$store.state.permission.permission_settings" elementKey="OG2gZCO" customClass="headerBtn4" actionKey="create" :keyPage="$route.name"/>
              </div>
            </div>
          </CCardHeader>
          <CCardBody>
            <DiscountTable
            :items="discounts"
            v-on:event_child="eventChild"
            />

            <AddDiscountModal
            :addDiscountModal="addDiscountModal"
            />
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <CModal
      title="Add Work Order"
      :show.sync="myModal"
      :no-close-on-backdrop="true"
      :centered="true"
      size="sm"
      color="dark"
    >
      <template #header>
        <h6 class="modal-title">ADD VEHICLE</h6>
        <!-- <CButtonClose @click="darkModal = false" class="text-white"/> -->
      </template>
      <CRow>
        <CCol sm="12">
            <CInput
                placeholder="VEHICLE NAME"
                v-model="form.vehicle_name"
            />
            <CSelect
                placeholder="Select Size"
                :options="size | selectData"
                :value.sync='form.size_id'
            />
        </CCol>
      </CRow>
      <template #footer>
        <CButton @click="submit" color="success" class="branding-btn">ADD</CButton>
        <CButton @click="myModal = false" color="danger">Cancel</CButton>
      </template>
    </CModal>

    <CModal
      title="ADD SERVICES"
      :show.sync="servicesModal"
      :no-close-on-backdrop="true"
      :centered="true"
      size="sm"
      color="dark"
    >
      <template #header>
        <h6 class="modal-title">ADD SERVICES</h6>
        <!-- <CButtonClose @click="darkModal = false" class="text-white"/> -->
      </template>
      <CRow>
        <CCol sm="12">
            <CInput
                placeholder="SERVICES NAME"
                v-model="form_services.services_name"
            />
            <CSelect
                placeholder="Select Category"
                :options="category | selectDataCategory"
                :value.sync='form_services.category_id'
            />
        </CCol>
      </CRow>
      <template #footer>
        <CButton @click="servicesSubmit" color="success" class="branding-btn">ADD</CButton>
        <CButton @click="servicesModal = false" color="danger">Cancel</CButton>
      </template>
    </CModal>

    <CModal
      title="EDIT SERVICES"
      :show.sync="editservicesModal"
      :no-close-on-backdrop="true"
      :centered="true"
      size="sm"
      color="dark"
    >
      <template #header>
        <h6 class="modal-title">EDIT SERVICES</h6>
        <!-- <CButtonClose @click="darkModal = false" class="text-white"/> -->
      </template>
      <CRow>
        <CCol sm="12">
            <CInput
                placeholder="SERVICES NAME"
                v-model="form_edit_services.services_name"
            />
            <CSelect
                placeholder="Select Category"
                :options="category | selectDataCategory"
                :value.sync='form_edit_services.category_id'
            />
        </CCol>
      </CRow>
      <template #footer>
        <CButton @click="servicesUpdateSubmit" color="success" class="branding-btn">Update</CButton>
        <CButton @click="editservicesModal = false" color="danger">Cancel</CButton>
      </template>
    </CModal>

    <CModal
      title="Add Work Order"
      :show.sync="editModal"
      :no-close-on-backdrop="true"
      :centered="true"
      size="sm"
      color="dark"
    >
      <template #header>
        <h6 class="modal-title">EDIT VEHICLE</h6>
        <!-- <CButtonClose @click="darkModal = false" class="text-white"/> -->
      </template>
      <CRow>
        <CCol sm="12">
            <CInput
                placeholder="VEHICLE NAME"
                v-model="form_edit.vehicle_name"
            />
            <CSelect
                placeholder="Select Size"
                :options="size | selectData"
                :value.sync='form_edit.size_id'
            />
        </CCol>
      </CRow>
      <template #footer>
        <CButton @click="updateVehicles" color="success" class="branding-btn">UPDATE</CButton>
        <CButton @click="editModal = false" color="danger">Cancel</CButton>
      </template>
    </CModal>

    <CModal
      :show.sync="dangerModal"
      :centered="true"
      title="Delete confirmation"
      :no-close-on-backdrop="true"
      size="sm"
      color="dark"
    >
      Are you sure you want to delete <b>{{form_delete.name}}</b>?
      <template #footer>
        <CButton @click="confirmDelete(form_delete.id)" color="success" class="branding-btn">YES</CButton>
        <CButton @click="dangerModal = false" color="danger">CANCEL</CButton>
      </template>
    </CModal>

    <CModal
      :show.sync="servicesDeleteModal"
      :centered="true"
      title="Delete confirmation"
      :no-close-on-backdrop="true"
      size="sm"
      color="dark"
    >
      Are you sure you want to delete <b>{{services_delete.services_name}}</b>?
      <template #footer>
        <CButton @click="confirmDeleteServices" color="success" class="branding-btn">YES</CButton>
        <CButton @click="servicesDeleteModal = false" color="danger">CANCEL</CButton>
      </template>
    </CModal>

    <EditDiscountModal
    :editDiscountModalData="editDiscountModalData"
    />
    <DeleteDiscountModal
    :deleteModalData="deleteModalData"
    />
    <SyncModal :syncModalData="syncModalData"/>
  </div>
</template>

<script>
import CTableWrapper from './VehicleTable.vue'
import DiscountTable from './DiscountTable'
import AddDiscountModal from './AddDiscountModal'
import EditDiscountModal from './EditDiscountModal'
import DeleteDiscountModal from './DeleteDiscountModal'
// import usersData from './users/UsersData'
import { mapGetters, mapActions } from 'vuex';
import RoleModal from "../../containers/RoleModal"
import ServicesTable from "./ServicesTable"
import SyncModal from "./SyncModal"

const fields = [
  { key: 'services_name', _style:'min-width:40%' },
  { 
    key: 'show_details', 
    label: '', 
    _style: 'min-width:1%'
  },
  { key: 'action', _style:'min-width:20%;' },
]

export default {
  name: 'Dashboard',
  components: {
      CTableWrapper,
      DiscountTable,
      AddDiscountModal,
      EditDiscountModal,
      DeleteDiscountModal,
      RoleModal,
      ServicesTable,
      SyncModal
  },
  data () {
      return {
          syncModalData: '',
          myModal: false,
          createPermission: false,
          updatePermission: false,
          deletePermission: false,
          addDiscountModal: '',
          deleteModalData: '',
          servicesModal: false,
          editModal: false,
          dangerModal: false,
          servicesDeleteModal: false,
          editservicesModal: false,
          editDiscountModalData: '',
          variation: [],
          form: {
              vehicle_name: '',
              size_id: '',
          },
          form_services: {
              services_name: '',
              category_id: ''
          },
          form_edit_services: {
              services_name: '',
              category_id: ''
          },
          form_edit: {
              id: '',
              vehicle_name: '',
              size_id: '',
          },
          form_delete: [],
          services_delete: [],
          servicesData: [],
          fields,
          details: [],
          collapseDuration: 0
      }
  },
  filters: {
        selectData: function (value) {
            if(value){
                var i;
                var size = [];
                for (i = 0; i < value.length; i++) {
                    if(value[i].size_name){
                        size.push({ 
                        value: value[i].id, 
                        label: value[i].size_name
                        }); 
                    }
                }
                return size;
            }
        },
        selectDataCategory: function (value) {
            if(value){
                var i;
                var category = [];
                for (i = 0; i < value.length; i++) {
                    if(value[i].category_name){
                        category.push({ 
                        value: value[i].id, 
                        label: value[i].category_name
                        }); 
                    }
                }
                return category;
            }
        },
        
  },
  computed: {
    ...mapGetters({ 
        size: "size/size",
        vehicle: "vehicle/vehicles",
        services: "services/services",
        category: "category/category",
        permission: "permission/permission",
        discounts: "discount/discount"
    }),
  },
  methods: {
    ...mapActions({
      addVehicle: "vehicle/addVehicle",
      addServices: "services/addServices",
      fetchSize: "size/fetchSize",
      fetchVehicle: "vehicle/fetchVehicle",
      removeVehicle: "vehicle/removeVehicle",
      updateVehicle: "vehicle/updateVehicle",
      fetchServices: "services/fetchServices",
      saveVariants: "services/saveVariant",
      fetchCategory: "category/fetchCategory",
      removeServices: "services/removeServices"
    }),
    openSyncModal(value){
      if(value == 'vehicle'){
        this.$store.dispatch('vehicle/getMainVehicle').then(response => {
          this.syncModalData = {
            trigger: new Date(),
            data: response,
            type: 'vehicle',
            fields: ['vehicle_name', 'size', 'action']
          }
        });
        
      }else if(value == 'services'){
        this.$store.dispatch('services/getMainServices').then(response => {
          this.syncModalData = {
            trigger: new Date(),
            data: response,
            type: 'services',
            fields: ['services_name', 'action']
          }
        });
        
      }else{
        this.$store.dispatch('discount/getMainDiscount').then(response => {
          this.syncModalData = {
            trigger: new Date(),
            data: response,
            type: 'discount',
            fields: ['discount_type', 'services', 'discount_percentage_or_fixed', 'action']
          }
        });
        
      }
    },
    saveVariant(id, price){
        var data = [id, price]
        this.saveVariants( data );
    },
    eventChild: function(data, status, type) {
      if(type == 'discount'){
        if(status == 'edit'){
          this.editDiscountModalData = {
            data: data,
            trigger: new Date()
          };
        }else{
          this.deleteModalData = {
            data: data,
            trigger: new Date()
          };
        }
      } else if(type == 'vehicle'){
        if(status == 'edit'){
          this.form_edit = data;
          this.editModal = true;
        }else{
          this.form_delete = data;
          this.dangerModal = true;
        }
      }else{
        if(status == 'edit'){
          console.log(data);
          this.getValueServices(data);
        }else{
          console.log('delete');
          this.getValueDelServices(data);
        }
      }
    },
    getValueDelServices(data){
      this.servicesDeleteModal = true;
      this.services_delete = data;
    },
    confirmDeleteServices(){
      this.removeServices(this.services_delete.id).then(() => {
        this.servicesDeleteModal = false;
        this.reFetch();
      });
      
    },
    submit(){
        this.addVehicle(this.form).then(() => {
            this.myModal = false;
            
            this.form.vehicle_name = '';
            this.form.size_id = '';

        });
        
    },
    servicesSubmit(){
        this.addServices(this.form_services).then(() => {
            this.servicesModal = false;
            
            this.form_services.services_name = '';

        });
    },
    getValueServices(data){
      this.editservicesModal = true;
      this.form_edit_services = data;
      
    },
    confirmDelete(id){
      this.dangerModal = false;
      this.removeVehicle(id);
    },
    updateVehicles() {
      this.updateVehicle(this.form_edit);
      this.editModal = false;
    },
    servicesUpdateSubmit(){
      this.$store.dispatch('services/updateEditServices', this.form_edit_services).then(()=> {
        this.editservicesModal = false;
      });
    },
    getShuffledUsersData () {
      return this.vehicle;
    },
    getBadge (status) {
      switch (status) {
        case 'Active': return 'success'
        case 'Inactive': return 'secondary'
        case 'Pending': return 'warning'
        case 'Banned': return 'danger'
        default: 'primary'
      }
    },
    
    
  },
  created(){
      this.fetchVehicle();
      this.fetchSize();
      this.fetchCategory();
      this.$store.dispatch('discount/fetchDiscount');
      this.fetchServices();
  }
}
</script>
