<template>
    <div>
      <CDataTable
        :hover="hover"
        :striped="striped"
        :border="border"
        :small="small"
        :fixed="fixed"
        :items="items"
        :fields="fields"
        :items-per-page="small ? 10 : 5"
        :dark="dark"
        :table-filter="true"
        pagination
        items-per-page-select
        :tableFilter='{ placeholder: "Search", label: " "}'
      >
        <template #services="{item}">
            <td>
                {{item.services.services_name}}
            </td>
        </template>
        <template #discount_percentage_or_fixed="{item}">
            <td v-if="item.percentage_type == '%'">
                {{item.discount_percentage}}%
            </td>
            <td v-else>
                {{item.discount_percentage}}
            </td>
        </template>
        <template #action="{item}">
            <td>
                <CButtonGroup>
                  <div style="position:relative;" v-if="$ability.can('update', '4d3K8AI')">
                    <CButton size="sm" @click="getValue(item)" color="info"><CIcon name="cil-pencil"/></CButton>
                    <RoleModal v-if="$store.state.permission.permission_settings" elementKey="4d3K8AI" customClass="tableBtn" actionKey="update" :keyPage="$route.name"/>
                  </div>
                  <div style="position:relative;" v-if="$ability.can('delete', 'Gidoo2T')">
                    <CButton size="sm" @click="getValueDel(item)" color="danger"><CIcon name="cil-trash"/></CButton>
                    <RoleModal v-if="$store.state.permission.permission_settings" elementKey="Gidoo2T" customClass="tableBtn" actionKey="delete" :keyPage="$route.name"/>
                  </div>
                </CButtonGroup>
            </td>
        </template>
      </CDataTable>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import RoleModal from "../../containers/RoleModal"

export default {
  name: 'Table',
  components: {
    RoleModal
  },
  data() {
      return {
          createPermission: false,
          updatePermission: false,
          deletePermission: false,
      }
  },
  props: {
    items: Array,
    fields: {
      type: Array,
      default () {
        return ['discount_type', 'services', 'discount_percentage_or_fixed', 'action']
      }
    },
    caption: {
      type: String,
      default: 'Table'
    },
    hover: Boolean,
    striped: Boolean,
    border: Boolean,
    small: Boolean,
    fixed: Boolean,
    dark: Boolean
  },
  computed: {
    ...mapGetters({ 
        permission: "permission/permission",
    }),
  },
  methods: {
    getBadge (status) {
      return status === 'Active' ? 'success'
        : status === 'Inactive' ? 'secondary'
          : status === 'Pending' ? 'warning'
            : status === 'Banned' ? 'danger' : 'primary'
    },
    getValue(data){
      this.$emit('event_child', data, 'edit', 'discount');
    },
    getValueDel(data){
      this.$emit('event_child', data, 'delete', 'discount');
    },
  },
  created(){
    if(this.permission){
      for(let i = 0; i < this.permission.length; i++){
        if(this.permission[i].route_name == "Customize"){
          this.createPermission = this.permission[i].create;
          this.updatePermission = this.permission[i].update;
          this.deletePermission = this.permission[i].delete;
        }
      }
    }
  }
}
</script>
