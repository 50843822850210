<template>
    <CModal
      :show.sync="myModal"
      :no-close-on-backdrop="true"
      :centered="true"
      
      color="dark"
    >
      <template #header>
        <h6 class="modal-title">SYNC DATA</h6>
        <!-- <CButtonClose @click="darkModal = false" class="text-white"/> -->
      </template>
      <CRow>
        <CCol>
            <SyncTable 
            :items="sync_data" 
            :fields="fields"
            v-on:event_child="eventChild"
            />
        </CCol>
      </CRow>
      <template #footer>
        <CButton @click="submit" color="success" class="branding-btn">SYNC</CButton>
        <CButton @click="myModal = false" color="danger">Cancel</CButton>
      </template>
    </CModal>
</template>
<script>
import vSelect from 'vue-select';
import SyncTable from './SyncTable';

export default {
    data(){
        return {
            myModal: false,
            fields: [],
            sync_data: []
        }
    },
    components: {
        vSelect,
        SyncTable
    },
    props: ['syncModalData'],
    watch: {
        syncModalData(value){
            this.myModal = true;
            this.sync_data = value.data;
            this.fields = value.fields;
        }
    },
    methods: {
        submit(){
          if(this.syncModalData.type == 'vehicle'){
            this.$store.dispatch('vehicle/syncVehicle', this.sync_data).then(() => {
              this.myModal = false;
            });
          }else if(this.syncModalData.type == 'services'){
            this.$store.dispatch('services/syncServices', this.sync_data).then(() => {
              this.myModal = false;
            });
          }else{
            this.$store.dispatch('discount/syncDiscount', this.sync_data).then(() => {
              this.myModal = false;
            });
          }
        },
        eventChild(value){
            let sync_data = this.sync_data.filter(veh => veh.id != value.id);
            this.sync_data = sync_data;
        }
    }
}
</script>