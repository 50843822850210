<template>
    <CModal
      title="Add Work Order"
      :show.sync="myModal"
      :no-close-on-backdrop="true"
      :centered="true"
      size="sm"
      color="dark"
    >
      <template #header>
        <h6 class="modal-title">EDIT DISCOUNTS</h6>
        <!-- <CButtonClose @click="darkModal = false" class="text-white"/> -->
      </template>
      <CRow>
        <CCol sm="12">
            <CSelect
                placeholder="Select Discount Type"
                label="Discount Type"
                :options="['10 points Discount', 'Regular Membership Discount']"
                :value.sync='form.discount_type'
            />
            <div class="form-group">
                <label>Services</label>
                <v-select
                    class="custom-new-select"
                    placeholder="Select Services"
                    :options="services | servicesFilter"
                    v-model='form.services_id'
                />
            </div>
            <CInput
                label="Discount Percentage"
                type="number"
                class="mt-3"
                v-model="form.discount_percentage"
            >
            <template #append>
                <CDropdown
                :togglerText="form.percentage_type"
                color="primary"
                right
                >
                    <CDropdownItem @click="selectedToggler('%')">%</CDropdownItem>
                    <CDropdownItem @click="selectedToggler('Fixed Amount')">Fixed Amount</CDropdownItem>
                </CDropdown>
            </template>
            </CInput>
        </CCol>
      </CRow>
      <template #footer>
        <CButton @click="submit" color="success" class="branding-btn">UPDATE</CButton>
        <CButton @click="myModal = false" color="danger">Cancel</CButton>
      </template>
    </CModal>
</template>
<script>
import { mapGetters } from 'vuex';
import vSelect from 'vue-select';

export default {
    data(){
        return {
            myModal: false,
            form: {
                id: '',
                discount_type: '',
                services_id: '',
                discount_percentage: '',
                percentage_type: '%',
            }
        }
    },
    components: {
        vSelect
    },
    props: ['editDiscountModalData'],
    watch: {
        editDiscountModalData(newData){
            this.form.id = newData.data.id;
            this.form.discount_type = newData.data.discount_type;
            this.form.services_id = {
                value: newData.data.services.id,
                label: newData.data.services.services_name,
            };
            this.form.discount_percentage = newData.data.discount_percentage;
            this.form.percentage_type = newData.data.percentage_type;
            this.myModal = true;
        }
    },
    filters: {
        servicesFilter(value){
            if(value){
              var i;
              var services = [];
              for (i = 0; i < value.length; i++) {
                  if(value[i]){
                      services.push({ 
                      value: value[i].id,
                      label: value[i].services_name
                      });
                  }
              }
              return services;
          }
        }
    },
    computed: {
        ...mapGetters({ 
            services: "services/services",
        }),
    },
    methods: {
        submit(){
            this.$store.dispatch('discount/updateDiscount', this.form).then(() => {
                this.myModal = false;
            });
        },
        selectedToggler(val){
            this.form.percentage_type = val;
        }
    }
}
</script>